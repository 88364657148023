import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'levab2x-page-not-found',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
})
export class PageNotFoundComponent {

  protected reason: string | null;

  constructor(protected route: ActivatedRoute) {
    this.reason = route.snapshot.queryParamMap.get('reason');
  }
}
