import { Component, OnInit, ViewEncapsulation, ElementRef, HostListener, ViewChild, InjectionToken, inject, Renderer2, AfterRenderPhase, afterRender } from "@angular/core";
import {
  AppStateService,
  StringUtility,
} from "@infosystem/baf-angular-web-common";
import { Router, RoutesRecognized } from '@angular/router';
import { LocalizationService, NavigationConfirmationRequiredGuard, OrderService, SupplierLogoAlignment } from "@infosystem/levab2x-lib-onlinecheckout-common";
import { TranslateService } from "@ngx-translate/core";
import { Align } from "@progress/kendo-angular-popup";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { initializeAppIcons } from './app.fontawesome-icons';
import { SupplierLogoSource } from './enums/supplier-logo-source';

export const SUPPLIER_LOGO_URL = new InjectionToken<string>("SUPPLIER_LOGO_URL");

@Component({
  selector: "levab2x-app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  //#region Fields
  private readonly levalLogoFallbackPath = 'assets/images/logo-genius.png';

  /** Exposes the enum for the template */
  protected LogoAlignment = SupplierLogoAlignment;

  private supplierLogoUrlTemplate: string = inject(SUPPLIER_LOGO_URL);

  /** The supplier logo url. This changes to different fallbacks on loading errors. */
  protected supplierLogoUrl?: string;

  /** The state for supplierLogoUrl. */
  private lastSupplierLogoLoadingSource = SupplierLogoSource.None;

  protected readonly order = this.service.order.asReadonly();
  protected readonly supplier = this.service.supplier.asReadonly();

  /** Whether to show the contact popup or not */
  protected showContact = false;
  /** Required for the contact popup (kendo-popup) alignemnt */
  protected contactAnchorAlign: Align = { horizontal: "left", vertical: "top" };
  /** Required for the contact popup (kendo-popup) alignemnt */
  protected contactPopupAlign: Align = { horizontal: "right", vertical: "center" };

  /** Whether to show the header's appbar or not. Driven by onscroll. */
  protected hideAppBar = false;
  private scrollPos = 0;
  private appBarHeight = 0;
  private pendingHeightCorrection = false;
  /* extra space being treated as bouncing area for appbar logic on top of the layout */
  private readonly topDeadzone = 50;
  /* extra space being treated as bouncing area for appbar login on bottom of the layout */
  private readonly btmDeadzone = 200;

  title = "leva b2x online checkout";
  //#endregion

  // Necessary for checking if the user clicks away from the popup
  @ViewChild("contactBtn", { read: ElementRef })
  public anchor: ElementRef | undefined;

  @ViewChild("contactPopup", { read: ElementRef })
  public popup: ElementRef | undefined;

  @ViewChild("appBar", { read: ElementRef })
  public appbar: ElementRef | undefined;

  @ViewChild("contentWrapper", { read: ElementRef })
  public contentWrapper: ElementRef | undefined;

  private navigationConfirmationRequiredGuard = inject(NavigationConfirmationRequiredGuard);

  public constructor(
    private renderer2 : Renderer2,
    private router: Router,
    protected service: OrderService,
    protected appStateService: AppStateService,
    protected localizationService: LocalizationService,
    private translateService: TranslateService,
    private iconLibrary: FaIconLibrary,
  ) {
    initializeAppIcons(iconLibrary)

    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
      this.appStateService.busyLabel = translateService.instant('lib.in_progress');
    });

    // Ensure the loading spinner is shown when the app is busy.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.appStateService.busyObservable$.subscribe((busy: any) => {
      this.appStateService.loading = busy;
    });

    // header size depends on an ngIf, based on two signals and then loading an image, so
    // we cannot rely on an effect, computed signal, viewchild setter or the init* lifecycle
    // events to calculate the final value (and it can still change when the page is resized)
    afterRender(() => this.getContentMargin(), {phase: AfterRenderPhase.EarlyRead});
    afterRender(() => this.setContentMargin(), {phase: AfterRenderPhase.Write});
  }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof RoutesRecognized &&
            event.state.root.firstChild?.routeConfig?.path != '**' &&
            event.state.root.firstChild?.routeConfig?.path != '') {

          const orderId = event.state.root.firstChild?.paramMap?.get('orderId');
          const jobId = event.state.root.firstChild?.firstChild?.paramMap?.get('jobId') ?? null;

          if(orderId == null){
            this.router.navigate(['/404']);
            return;
          }
          this.service.setId(orderId, jobId);

          if(this.service.order() == null || this.service.order()?.deeplinkId != orderId) {
            this.service.getOrder(orderId).subscribe((value) => {
              this.tryNextSupplierLogoSource();

              if(value == null) {
                this.router.navigate(['/404'], { skipLocationChange: true });
                return;
              }
            });
          }
        }
      });
  }

  /** Reads the height of the header */
  private getContentMargin() : void {
    const h = this.appbar?.nativeElement?.offsetHeight;
    if(h == undefined || h < 1 || h == this.appBarHeight)
      return;

    this.appBarHeight = h;
    this.pendingHeightCorrection = true;
  }

  /** Updates the content margin when needed */
  private setContentMargin() : void {
    if(!this.pendingHeightCorrection)
      return;

    this.renderer2.setStyle(this.contentWrapper?.nativeElement, "margin-top", this.appBarHeight +"px");
    this.pendingHeightCorrection = false;
  }

  //#region HostListeners
  /** Hides the contact popup if it is shown but the user clicks away */
  @HostListener("document:click", ["$event"])
  protected documentClick(event: KeyboardEvent): void {
    if(event?.target == undefined || this.anchor == undefined || this.popup == undefined) {
      return;
    }

    if(!(this.anchor?.nativeElement.contains(event.target) || this.popup?.nativeElement.contains(event.target))) {
      this.showContact = false;
    }
  }

  /** Handles the appbar's visibility. */
  @HostListener("window:scroll", ["$event"])
  public onScroll(event: Event): void {

    // prevents feature on bigger screens
    if(!window.matchMedia("only screen and (max-width: 760px)").matches)
    {
      this.hideAppBar = false;
      this.scrollPos = 0;
      return;
    }

    const target = event.target instanceof Element ? event.target : document.scrollingElement;
    if(target) {
      const pos = target.scrollTop;
      const bouncing =
        // scrolling below the content
        (target.scrollHeight - this.btmDeadzone) < (Math.ceil(pos) + document.documentElement.offsetHeight) ||
        // scrolling above the content
        pos < 0 + this.topDeadzone;

      // ignores (iOS) bouncing / deadzone
      if(bouncing) {
        return;
      }

      // hides when scrolling down, shows otherwise
      this.hideAppBar = this.scrollPos < pos;
      this.scrollPos = pos;
    }
  }
  //#endregion


  protected onContactToggle() : void {
    this.showContact = !this.showContact;
  }

  protected onSupplierLogoClick() {
    this.navigationConfirmationRequiredGuard.navigateOrConfirm().subscribe(doNavigate => {
      if(doNavigate){
        this.router.navigate(['order', this.service.order()?.deeplinkId])
      }
    });
  }

  /** Supplier logo img.error event. */
  protected onSupplierLogoLoadingError(): void {
    // ignore img src errors from the html template before the order is loaded (e.g. img src is empty string).
    if(this.service.supplier()) {
      this.tryNextSupplierLogoSource();
    }
  }

  /** Tries the next supplier logo url after a failure.
   * If the logo url is null on a path, falls through to the next.
   */
  private tryNextSupplierLogoSource(): void {

    // load from url parameter
    if(this.lastSupplierLogoLoadingSource === SupplierLogoSource.None){
      this.lastSupplierLogoLoadingSource++;
      if(this.service.supplier()) {
        this.supplierLogoUrl = StringUtility.format(this.supplierLogoUrlTemplate, this.service.supplier()?.id);
        return;
      }
    }

    if(this.lastSupplierLogoLoadingSource === SupplierLogoSource.DataModelSupplierId){
      this.lastSupplierLogoLoadingSource++;
      if(this.service.supplier()) {
        this.supplierLogoUrl = this.service.supplier()?.logoUrl;
        if(this.supplierLogoUrl != null && this.supplierLogoUrl != '') {
          return;
        }
      }
    }

    if(this.lastSupplierLogoLoadingSource === SupplierLogoSource.DataModelUrl){
      this.lastSupplierLogoLoadingSource++;
      this.supplierLogoUrl = this.levalLogoFallbackPath;
    }
  }
}
