import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { CommonModule, registerLocaleData, DatePipe, DecimalPipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpBackend, provideHttpClient } from '@angular/common/http';
import { appRoutes } from './app.routes';
import { environment } from './../environments/environment';
import { AppComponent, SUPPLIER_LOGO_URL } from "./app.component";
import { PreloadAllModules, RouterModule } from '@angular/router';

import { AppStateService, BafAngularCommonModule, BASE_URLS, BaseUrls } from "@infosystem/baf-angular-web-common";
import { BafAngularUIModule } from "@infosystem/baf-angular-web-ui";

import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { PopupModule } from "@progress/kendo-angular-popup";
import { TooltipsModule } from "@progress/kendo-angular-tooltip";

// Internationalization Kendo UI
// https://www.telerik.com/kendo-angular-ui/components/globalization/internationalization/
import "@progress/kendo-angular-intl/locales/de-CH/all";
import "@progress/kendo-angular-intl/locales/en/all";
// import "@progress/kendo-angular-intl/locales/fr-CH/all";
// import "@progress/kendo-angular-intl/locales/it-CH/all";
// import "@progress/kendo-angular-intl/locales/hu/all";

import localeDeCh from "@angular/common/locales/de-CH";
import localeEn from "@angular/common/locales/en";
// import localeFrCh from "@angular/common/locales/fr-CH";
// import localeItCh from "@angular/common/locales/it-CH";
// import localeHu from "@angular/common/locales/hu";

import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";

import { API_URL, AVAILABLE_LOCALES, KendoLocalizationService, LocalizationService, NavigationConfirmationRequiredGuard } from '@infosystem/levab2x-lib-onlinecheckout-common';
import { CldrIntlService, IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';

const baseUrls = new BaseUrls();

registerLocaleData(localeDeCh, 'de-CH');
registerLocaleData(localeEn, 'en-US');
// TODO: add more language support after MVP
// registerLocaleData(localeFrCh, 'fr-CH');
// registerLocaleData(localeItCh, 'it-CH');
// registerLocaleData( localeHu, 'hu-HU');

// ngx-translate: AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpBackend, apiUrl: string) {
  // Merge the text resources from libraries and the application.
  // Note: if multiple resources have the same key, they are overwritten in the order of loading (as defined here).
  return new MultiTranslateHttpLoader(http, [
    // the application resources (also contains the BAF resources)
    { prefix: "./assets/i18n/" },
    // the main library resources
    { prefix: "./assets/i18n/onlinecheckout/" },
    { prefix: apiUrl + "localization/", suffix: '', optional: true },
  ]);
}

export function appInitializerFactory(
  localizationService: LocalizationService,
  translateService: TranslateService,
  appStateService: AppStateService
) {
  return async () => {
    return new Promise(resolve =>{
      translateService.onLangChange.subscribe(() => {
        appStateService.busyLabel = translateService.instant('lib.in_progress');
        resolve(null);
      });
      localizationService.initialize();
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      enableViewTransitions: true,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend, API_URL],
      },
    }),
    IntlModule,
    BafAngularCommonModule,
    BafAngularUIModule,
    ButtonsModule,
    NavigationModule,
    PopupModule,
    TooltipsModule
  ],
  providers: [
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      deps: [LocalizationService, TranslateService, AppStateService],
      useFactory: appInitializerFactory,
      multi: true
    },
    { provide: BASE_URLS, useValue: baseUrls },
    { provide: API_URL, useValue: environment.API_URL },
    { provide: SUPPLIER_LOGO_URL, useValue: environment.SUPPLIER_LOGO_URL },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (service: LocalizationService) => service.locale()
    },
    { provide: AVAILABLE_LOCALES, useValue: environment.availableLocales },
    CldrIntlService,
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    { provide: MessageService, useClass: KendoLocalizationService },
    DatePipe,
    DecimalPipe,
    AppStateService,
    TranslateService,
    LocalizationService,
    NavigationConfirmationRequiredGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
