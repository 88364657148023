import { Routes } from '@angular/router';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'order/:orderId',
    loadChildren: () => import('@infosystem/levab2x-lib-onlinecheckout').then(mod => mod.orderRoutes)
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];
